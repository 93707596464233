// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2666666667);
  display: flex;
  float: none;
  margin: 0;
  padding: 4px;
}
:host.menubar-top {
  flex-direction: row;
  justify-content: flex-end;
}
:host.menubar-left {
  flex-direction: column;
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/app/components/menubar/menubar.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,6CAAA;EACA,aAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;EACA,yBAAA;AACJ;AAEE;EACE,sBAAA;EACA,2BAAA;AAAJ","sourcesContent":[":host {\n  align-items: center;\n  background-color: #0004;\n  display: flex;\n  float: none;\n  margin: 0;\n  padding: 4px;\n\n  &.menubar-top {\n    flex-direction: row;\n    justify-content: flex-end;\n  }\n\n  &.menubar-left {\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
