import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IThemeProvider, SciChartJSDarkv2Theme, SciChartJSLightTheme, SciChartJsNavyTheme } from 'scichart';

export type LitTheme = 'light' | 'dark' | 'custom';

const SCI_CHART_THEMES = {
  navy: new SciChartJsNavyTheme(),
  light: new SciChartJSLightTheme(),
  dark: new SciChartJSDarkv2Theme()
};

export type ChartTheme = keyof typeof SCI_CHART_THEMES | 'auto' | 'auto-inverse';
export type ChartTraffic = 'POLLING' | 'SOCKET';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private _desktopEnvironment: boolean;
  private _desktopEnvironment$ = new Subject<boolean>();
  public desktopEnvironment$ = this._desktopEnvironment$.asObservable();
  public get desktopEnvironment(): boolean {
    return this._desktopEnvironment;
  }
  public set desktopEnvironment(v: boolean) {
    if (v === null || v === undefined) return;
    this._desktopEnvironment = v;
    localStorage.setItem('lit-desktop-environment', v ? 'true' : 'false');
    this._desktopEnvironment$.next(v);
  }

  private _theme: LitTheme;
  private _theme$ = new Subject<LitTheme>();
  public theme$ = this._theme$.asObservable();
  public get theme(): LitTheme {
    return this._theme;
  }
  public set theme(v: LitTheme) {
    if (v === null || v === undefined) return;
    document.body.parentElement.classList.replace(this._theme, v);
    this._theme = v;
    localStorage.setItem('lit-theme', v);
    this._theme$.next(v);
  }

  private _chartTheme: ChartTheme;
  private _chartTheme$ = new Subject<ChartTheme>();
  public chartTheme$ = this._chartTheme$.asObservable();
  public get chartTheme(): ChartTheme {
    return this._chartTheme;
  }
  public set chartTheme(v: ChartTheme) {
    if (v === null || v === undefined) return;
    this._chartTheme = v;
    localStorage.setItem('chart-theme', v);
    this._chartTheme$.next(v);
  }

  private _appBackground: string;
  private _appBackground$ = new Subject<string>();
  public appBackground$ = this._appBackground$.asObservable();
  public get appBackground(): string {
    return this._appBackground;
  }
  public set appBackground(v: string) {
    if (v === null || v === undefined) return;
    this._appBackground = v;
    document.body.style.cssText = `--bg-${this.backgroundType}: ${v}`;
    localStorage.setItem('app-background', v);
    this._appBackground$.next(v);
  }

  private _backgroundFit: string;
  private _backgroundFit$ = new Subject<string>();
  public backgroundFit$ = this._backgroundFit$.asObservable();
  public get backgroundFit(): string {
    return this._backgroundFit;
  }
  public set backgroundFit(v: string) {
    if (v === null || v === undefined) return;
    document.body.classList.replace(`bg-${this._backgroundFit}`, `bg-${v}`);
    this._backgroundFit = v;
    localStorage.setItem('background-fit', v);
    this._backgroundFit$.next(v);
  }

  private _menubarPosition: 'top' | 'left';
  private _menubarPosition$ = new Subject<'top' | 'left'>();
  public menubarPosition$ = this._menubarPosition$.asObservable();
  public get menubarPosition(): 'top' | 'left' {
    return this._menubarPosition;
  }
  public set menubarPosition(v: 'top' | 'left') {
    if (v === null || v === undefined) return;
    this._menubarPosition = v;
    localStorage.setItem('menubar-position', v);
    this._menubarPosition$.next(v);
  }

  private _pollingRate: number;
  private _pollingRate$ = new Subject<number>();
  public pollingRate$ = this._pollingRate$.asObservable();
  public get pollingRate(): number {
    return this._pollingRate;
  }
  public set pollingRate(v: number) {
    if (v === null || v === undefined) return;
    this._pollingRate = v;
    localStorage.setItem('polling-rate', v.toString());
    this._pollingRate$.next(v);
  }

  private _chartTraffic: ChartTraffic;
  private _chartTraffic$ = new Subject<ChartTraffic>();
  public chartTraffic$ = this._chartTraffic$.asObservable();
  public get chartTraffic(): ChartTraffic {
    this._chartTraffic = localStorage.getItem('chart-traffic') as ChartTraffic;
    return this._chartTraffic ? this._chartTraffic : 'POLLING';
  }

  public set chartTraffic(v: ChartTraffic) {
    if (v === null || v === undefined) return;
    this._chartTraffic = v;
    localStorage.setItem('chart-traffic', v.toString());
    this._chartTraffic$.next(v);
  }

  public get hasCustomTheme(): 'light' | 'dark' | undefined {
    return window.env.prebuild.hasCustomTheme;
  }

  public get backgroundType(): 'image' | 'color' {
    return this._appBackground.startsWith('#') ? 'color' : 'image';
  }

  private _loadDesktopEnvironment(): boolean {
    const stored = localStorage.getItem('lit-desktop-environment');
    return stored === 'true';
  }

  private _loadTheme(): LitTheme {
    const stored = localStorage.getItem('lit-theme');

    if (stored === 'custom' && this.hasCustomTheme) return 'custom';
    if (stored === 'light') return 'light';
    if (stored === 'dark') return 'dark';
    if (Object.getOwnPropertyNames(window).indexOf('matchMedia') === -1) return 'light';
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) return 'dark';

    return 'light';
  }

  private _loadChartTheme(): ChartTheme {
    const stored = localStorage.getItem('chart-theme');

    if (stored in SCI_CHART_THEMES) return stored as ChartTheme;
    if (stored === 'auto-inverse') return 'auto-inverse';

    return 'auto';
  }

  private _loadAppBackground(): string {
    return localStorage.getItem('app-background') ?? 'url(/assets/yosemite-2.webp)';
  }

  private _loadBackgroundFit(): string {
    switch (localStorage.getItem('background-fit') ?? '') {
      case 'fill':
        return 'fill';
      case 'tile':
        return 'tile';
      case 'center':
        return 'center';
      default:
        return 'fill';
    }
  }

  private _loadMenubarPosition(): 'top' | 'left' {
    switch (localStorage.getItem('menubar-position') ?? '') {
      case 'top':
        return 'top';
      case 'left':
        return 'left';
      default:
        return 'left';
    }
  }

  private _loadPollingRate(): number {
    const stored = localStorage.getItem('polling-rate');

    if (['30000', '60000', '180000', '300000'].includes(stored)) return parseInt(stored);

    return 60_000;
  }

  constructor() {
    window.$settings = this;

    this._desktopEnvironment = this._loadDesktopEnvironment();
    this._theme = this._loadTheme();
    this._chartTheme = this._loadChartTheme();
    this._appBackground = this._loadAppBackground();
    this._backgroundFit = this._loadBackgroundFit();
    this._menubarPosition = this._loadMenubarPosition();
    this._pollingRate = this._loadPollingRate();
  }

  public getChartThemeName(): ChartTheme {
    if (this._chartTheme in SCI_CHART_THEMES) return this._chartTheme;

    if (this._chartTheme === 'auto') return this._theme === 'light' ? 'navy' : 'light';
    if (this._chartTheme === 'auto-inverse') return this._theme === 'light' ? 'light' : 'navy';

    return 'navy';
  }

  public getChartThemeProvider(): IThemeProvider {
    return SCI_CHART_THEMES[this.getChartThemeName()];
  }
}
