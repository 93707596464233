import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent, MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { isNil } from 'lodash';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export type DatePeriod = `${number}${'week' | 'month'}`;
export type DateRangePreset = DatePeriod | 'custom';

export function datePeriodToDays(period: DatePeriod): number {
  const { count, mult } = /(?<count>\d+)(?<mult>week|month)/.exec(period).groups;
  return parseInt(count) * (mult === 'month' ? 30 : 7);
}

export function datePeriodToDates(period: DatePeriod): [Date, Date] {
  const backwardDays = datePeriodToDays(period);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0).getTime();
  const start = today - MS_PER_DAY * backwardDays;

  return [new Date(start), new Date(today)];
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit, AfterViewInit {
  @ViewChild('picker') picker: MatDateRangePicker<Date>;
  @Input() preset: DateRangePreset | undefined = undefined;
  @Input() range: [Date | undefined, Date | undefined] | undefined = undefined;
  @Output() presetChange = new EventEmitter<DateRangePreset>();
  @Output() rangeChange = new EventEmitter<[Date, Date]>();

  ngOnInit(): void {
    this.reflectPresetToRange();
  }

  ngAfterViewInit(): void {
    setTimeout(this.reflectPresetToPicker.bind(this), 0);
  }

  public reflectPresetToRange(): void {
    if (isNil(this.preset) || this.preset === 'custom') return;

    this.range = datePeriodToDates(this.preset);
  }

  public reflectPresetToPicker(): void {
    if (isNil(this.preset) || this.preset === 'custom') return;

    this.picker.select(this.range[0]);
    this.picker.select(this.range[1]);
  }

  public $changePreset({ value: preset }: { value: DateRangePreset }): void {
    this.presetChange.emit(preset);

    if (preset !== 'custom') {
      this.reflectPresetToRange();
      this.reflectPresetToPicker();
      this.rangeChange.emit(this.range);
    } else {
      this.range = [undefined, undefined];
    }
  }

  public $changeDateRange({ value }: MatDatepickerInputEvent<Date, MatStartDate<Date> | MatEndDate<Date>>, side: 'start' | 'end'): void {
    if (this.preset !== 'custom') return;

    if (side === 'start') this.range[0] = value;
    if (side === 'end') this.range[1] = value;

    if (!isNil(this.range[0]) && !isNil(this.range[1])) {
      this.rangeChange.emit(this.range);
    }
  }
}
