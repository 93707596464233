// Angular imports
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { TeamsMainComponent } from './components/teams/main/main.component';
import { ExperimentsComponent } from './components/models/experiments/experiments.component';

// Modules
import { MaterialModule } from 'src/app/modules/shared/material.module';
import { SocketIoModule } from 'ngx-socket-io';
import { SocketIoConfig } from 'ngx-socket-io';
import { AngularSplitModule } from 'angular-split';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { GridsterModule } from 'angular-gridster2';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Services
import { ConfigService } from './services/config.service';
import { IdentityService } from './services/identity.service';
import { PaletteService } from './services/palette.service';
import { PerfTableComponent } from './components/models/perf-table/perf-table.component';
import { PerfChartComponent } from './components/models/perf-chart/perf-chart.component';
import { HomeComponent } from './components/home/home.component';
import { GpusComponent } from './components/devices/gpus/gpus.component';
import { HostsComponent } from './components/devices/hosts/hosts.component';
import { VaultComponent } from './components/models/vault/vault.component';
import { HostComponent } from './components/devices/host/host.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HostUserStatsComponent } from './components/devices/host-user-stats/host-user-stats.component';
import { HostGpuStatsComponent } from './components/devices/host-gpu-stats/host-gpu-stats.component';
import { HostTimelineComponent } from './components/devices/host-timeline/host-timeline.component';
import { DiscoveryComponent } from './components/data/discovery/discovery.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ProjectsComponent } from './components/team/projects/projects.component';
import { UserHostStatsComponent } from './components/team/user-host-stats/user-host-stats.component';
import { UserProjectStatsComponent } from './components/team/user-project-stats/user-project-stats.component';
import { ProjectComponent } from './components/team/project/project.component';
import {
  FormatSecondsPipe,
  FormatUnixTimePipe,
  FormatBytes,
  FormatToTitleCase,
  SafeNumberPipe,
  FormatEllipsize
} from './pipes/format.pipe';
import { ModelViewComponent } from './components/models/model-view/model-view.component';
import { ModelDiffComponent } from './components/models/model-diff/model-diff.component';
import { VaultDetailComponent } from './components/models/vault-detail/vault-detail.component';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { GlobalErrorHandler } from './global-error-handler';
import { FeaturesComponent } from './components/data/features/features.component';
import { SchemasComponent } from './components/data/schemas/schemas.component';
import { FeatureComponent } from './components/data/feature/feature.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { SchemaComponent } from './components/data/schema/schema.component';
import { PeopleComponent } from './components/team/people/people.component';
import { PersonComponent } from './components/team/person/person.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { NgTerminalModule } from 'ng-terminal';
import { HostPasswordDialogComponent } from './components/host-password-dialog/host-password-dialog.component';
import { BuildsComponent } from './components/data/builds/builds.component';
import { PidsComponent } from './components/data/pids/pids.component';
import { AssetsComponent } from './components/data/assets/assets.component';
import { AssetComponent } from './components/data/asset/asset.component';
import { AssetLogsComponent } from './components/data/asset-logs/asset-logs.component';
import { BuildComponent } from './components/data/build/build.component';
import { RawComponent } from './components/data/raw/raw.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { ControlComponent } from './components/dynamic-form/control/control.component';
import { TextComponent } from './components/dynamic-form/control/controls/text/text.component';
import { CheckboxComponent } from './components/dynamic-form/control/controls/checkbox/checkbox.component';
import { LogComponent } from './components/data/log/log.component';
import { DeployComponent } from './components/models/deploy/deploy.component';
import { DeploysComponent } from './components/models/deploys/deploys.component';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { TvChartContainerComponent } from './components/tv-chart-container/tv-chart-container.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { DefinitionsComponent } from './components/models/definitions/definitions.component';
import { DefinitionComponent } from './components/models/definition/definition.component';
import { SelectComponent } from './components/dynamic-form/control/controls/select/select.component';
import { InspectComponent } from './components/data/inspect/inspect.component';
import { SamplesComponent } from './components/data/inspect/samples/samples.component';
import { CurrencyMaskDirectiveDirective } from './directives/currency-mask-directive.directive';
import { ScreenDialogComponent } from './components/screen-dialog/screen-dialog.component';
import { StreamsComponent } from './components/models/streams/streams.component';
import { ChartComponent } from './components/models/chart/chart.component';
import { StreamComponent } from './components/models/stream/stream.component';
import { WorkflowCanvasComponent } from './components/models/definition/workflow-canvas/workflow-canvas.component';
import { WorkflowCanvasGridComponent } from './components/models/definition/workflow-canvas/workflow-canvas-grid.component';
import { WorkflowCanvasInPortComponent } from './components/models/definition/workflow-canvas/workflow-canvas-inport.component';
import { WorkflowCanvasOutPortComponent } from './components/models/definition/workflow-canvas/workflow-canvas-outport.component';
import { WorkflowCanvasShapeComponent } from './components/models/definition/workflow-canvas/workflow-canvas-shape.component';
import { WorkflowCanvasNodeComponent } from './components/models/definition/workflow-canvas/workflow-canvas-node.component';
import { WorkflowCanvasNodeTitleComponent } from './components/models/definition/workflow-canvas/workflow-canvas-node-title.component';
import {
  WorkflowCanvasConnectionComponent,
  WorkflowCanvasConnectionPreviewComponent
} from './components/models/definition/workflow-canvas/workflow-canvas-connection.component';
import { DirectoryComponent } from './components/data/directory/directory.component';
import { MatRippleModule } from '@angular/material/core';
import { MarkdownDialogComponent } from './components/markdown-dialog/markdown-dialog.component';
import { MarkdownModule } from 'ngx-markdown';
import { MoveRawDialogComponent } from './components/move-raw-dialog/move-raw-dialog.component';
import { DeployCardComponent } from './components/models/deploy-card/deploy-card.component';
import { NewFeatureComponent } from './components/data/new-feature/new-feature.component';
import { CodeViewComponent } from './components/code-view/code-view.component';
import { DataViewerComponent } from './components/data/data-viewer/data-viewer.component';
import { CsvViewerComponent } from './components/data/csv-viewer/csv-viewer.component';
import { WavViewerComponent } from './components/data/wav-viewer/wav-viewer.component';
import { ExploreComponent } from './components/data/explore/explore.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SVGCanvasComponent } from './components/svg-canvas/svg-canvas.component';
import { ProjectBoardComponent } from './components/team/project-board/project-board.component';
import { ProjectBoardArrowComponent } from './components/team/project-board/project-board-arrow.component';
import { ProjectBoardNodeComponent } from './components/team/project-board/project-board-node.component';
import { TimelineComponent, TimelineEventComponent } from './components/timeline/timeline.component';
import { HUDComponent } from './components/hud/hud.component';
import { AnalysisContainerComponent } from './components/models/analysis-container/analysis-container.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AnalysisComponent } from './components/models/analysis-container/analysis/analysis.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { SettingsDialogComponent } from './components/settings-dialog/settings-dialog.component';
import { SettingsService } from './services/settings.service';
import { LiveChipComponent } from './components/live-chip/live-chip.component';
import { ExploreChartComponent } from './components/explore-chart/explore-chart.component';
import { ExploreCandlestickContainerComponent } from './components/explore-candlestick-container/explore-candlestick-container.component';
import { initializeSciChart } from './init/scichart-init.factory';
import { InsightsLineChartComponent } from './components/insights-line-chart/insights-line-chart.component';
import { InsightsCsChartComponent } from './components/insights-cs-chart/insights-cs-chart.component';
import { ExploreDataDialogComponent } from './components/explore-chart/explore-data-dialog/explore-data-dialog.component';
import { GridComponent } from './components/strategy-grid/grid/grid.component';
import { DynamicModule } from 'ng-dynamic-component';
import { ModelChartWidgetComponent } from './components/strategy-grid/model-chart-widget/model-chart-widget.component';
import { StreamWidgetComponent } from './components/strategy-grid/stream-widget/stream-widget.component';
import { TickWidgetComponent } from './components/strategy-grid/tick-widget/tick-widget.component';
import { PacketWidgetComponent } from './components/strategy-grid/packet-widget/packet-widget.component';
import { ModelInputsWidgetComponent } from './components/strategy-grid/model-inputs-widget/model-inputs-widget.component';
import { StrategyWidgetComponent } from './components/strategy-grid/strategy-widget/strategy-widget.component';
import { BreakoutWidgetComponent } from './components/strategy-grid/breakout-widget/breakout-widget.component';
import { BaseWidgetComponent } from './components/strategy-grid/base-widget/base-widget.component';
import { PaletteInputComponent } from './components/palette-input/palette-input.component';
import { ExperimentsWidgetComponent } from './components/strategy-grid/experiments-widget/experiments-widget.component';
import { RawDataWidgetComponent } from './components/strategy-grid/raw-data-widget/raw-data-widget.component';
import { FeatureSelectorWidgetComponent } from './components/strategy-grid/feature-selector-widget/feature-selector-widget.component';
import { SchemasWidgetComponent } from './components/strategy-grid/schemas-widget/schemas-widget.component';
import { BuildsWidgetComponent } from './components/strategy-grid/builds-widget/builds-widget.component';
import { AssetsWidgetComponent } from './components/strategy-grid/assets-widget/assets-widget.component';
import { DefinitionsWidgetComponent } from './components/strategy-grid/definitions-widget/definitions-widget.component';
import { VaultWidgetComponent } from './components/strategy-grid/vault-widget/vault-widget.component';
import { DeploysWidgetComponent } from './components/strategy-grid/deploys-widget/deploys-widget.component';
import { HostsWidgetComponent } from './components/strategy-grid/hosts-widget/hosts-widget.component';
import { GpusWidgetComponent } from './components/strategy-grid/gpus-widget/gpus-widget.component';
import { ProjectsWidgetComponent } from './components/strategy-grid/projects-widget/projects-widget.component';
import { PeopleWidgetComponent } from './components/strategy-grid/people-widget/people-widget.component';
import { TerminalWidgetComponent } from './components/strategy-grid/terminal-widget/terminal-widget.component';
import { InspectWidgetComponent } from './components/strategy-grid/inspect-widget/inspect-widget.component';
import { ChatWidgetComponent } from './components/strategy-grid/chat-widget/chat-widget.component';
import { SparklinesWidgetComponent } from './components/strategy-grid/sparklines-widget/sparklines-widget.component';
import { IndicatorWidgetComponent } from './components/strategy-grid/indicator-widget/indicator-widget.component';
import { WidgetTitlebarComponent } from './components/strategy-grid/widget-titlebar/widget-titlebar.component';
import { LabWidgetComponent } from './components/strategy-grid/lab-widget/lab-widget.component';
import { SettingsWidgetComponent } from './components/strategy-grid/settings-widget/settings-widget.component';
import { ProjectSelectorComponent } from './components/project-selector/project-selector.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { FeatureSelectorComponent } from './components/feature-selector/feature-selector.component';
import { ModelSelectorComponent } from './components/model-selector/model-selector.component';
import { PrettyDescriptionComponent } from './components/dynamic-form/pretty-description/pretty-description.component';
import { LastBuildSelectorComponent } from './components/last-build-selector/last-build-selector.component';
import { VaultSelectorComponent } from './components/vault-selector/vault-selector.component';
import { DeploySelectorComponent } from './components/deploy-selector/deploy-selector.component';
import { InitService } from './services/init.service';
import { InitComponent } from './components/init/init.component';
import { MonacoWidgetComponent } from './components/strategy-grid/monaco-widget/monaco-widget.component';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
import { StrategyEditorWidgetComponent } from './components/strategy-grid/strategy-editor-widget/strategy-editor-widget.component';
import { StreamsWidgetComponent } from './components/strategy-grid/streams-widget/streams-widget.component';
import ServiceStatusIconComponent from './components/service-status-icon/service-status-icon.component';
import { OmnibarComponent } from './components/omnibar/omnibar.component';
import { InfoSnackbarComponent } from './components/info-snackbar/info-snackbar.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CompilerWidgetComponent } from './components/strategy-grid/compiler-widget/compiler-widget.component';
import { StreamRestWidgetComponent } from './components/strategy-grid/stream-rest-widget/stream-rest-widget.component';
import { IFrameTrackerDirective } from './directives/iframe-tracker';
import { StreamApiStatsComponent } from './components/stream-api-stats/stream-api-stats.component';
import { StreamApiLogsComponent } from './components/stream-api-logs/stream-api-logs.component';
import { MasSplitButtonModule } from '@material-spirit/ngx-split-button';
import { PerfTableWidgetComponent } from './components/strategy-grid/perf-table-widget/perf-table-widget.component';
import { ChatScreenComponent } from './components/chat-screen/chat-screen.component';
import { ChatDialogComponent } from './components/chat-dialog/chat-dialog.component';
import { SparklineChartComponent } from './components/strategy-grid/sparklines-widget/sparkline-chart.component';
import { AgentWidgetComponent } from './components/strategy-grid/agent-widget/agent-widget.component';
import { PromptsWidgetComponent } from './components/strategy-grid/prompts-widget/prompts-widget.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { IndicatorsWidgetComponent } from './components/strategy-grid/indicators-widget/indicators-widget.component';
import { MenubarComponent } from './components/menubar/menubar.component';

const config: SocketIoConfig = { url: ConfigService.socketUrl, options: { transports: ['websocket'] } };

@NgModule({
  declarations: [
    AppComponent,
    TeamsMainComponent,
    ExperimentsComponent,
    PerfTableComponent,
    PerfChartComponent,
    HomeComponent,
    GpusComponent,
    HostsComponent,
    VaultComponent,
    HostComponent,
    SpinnerComponent,
    HostUserStatsComponent,
    HostGpuStatsComponent,
    HostTimelineComponent,
    DiscoveryComponent,
    SafeUrlPipe,
    ProjectsComponent,
    UserHostStatsComponent,
    UserProjectStatsComponent,
    ProjectComponent,
    FormatSecondsPipe,
    SafeNumberPipe,
    FormatUnixTimePipe,
    FormatBytes,
    ModelViewComponent,
    ModelDiffComponent,
    VaultDetailComponent,
    FeaturesComponent,
    SchemasComponent,
    SchemaComponent,
    DefinitionsComponent,
    DefinitionComponent,
    WorkflowCanvasComponent,
    WorkflowCanvasGridComponent,
    WorkflowCanvasInPortComponent,
    WorkflowCanvasOutPortComponent,
    WorkflowCanvasShapeComponent,
    WorkflowCanvasNodeComponent,
    WorkflowCanvasNodeTitleComponent,
    WorkflowCanvasConnectionPreviewComponent,
    WorkflowCanvasConnectionComponent,
    FeatureComponent,
    PeopleComponent,
    PersonComponent,
    TerminalComponent,
    HostPasswordDialogComponent,
    BuildsComponent,
    BuildComponent,
    PidsComponent,
    AssetsComponent,
    RawComponent,
    AssetComponent,
    AssetLogsComponent,
    DynamicFormComponent,
    ControlComponent,
    TextComponent,
    CheckboxComponent,
    SelectComponent,
    LogComponent,
    DeployComponent,
    DeploysComponent,
    ChipListComponent,
    TvChartContainerComponent,
    FileUploadDialogComponent,
    InspectComponent,
    SamplesComponent,
    CurrencyMaskDirectiveDirective,
    IFrameTrackerDirective,
    ScreenDialogComponent,
    StreamsComponent,
    ChartComponent,
    StreamComponent,
    DirectoryComponent,
    MarkdownDialogComponent,
    MoveRawDialogComponent,
    DeployCardComponent,
    NewFeatureComponent,
    CodeViewComponent,
    DataViewerComponent,
    CsvViewerComponent,
    WavViewerComponent,
    ExploreComponent,
    SVGCanvasComponent,
    ProjectBoardComponent,
    ProjectBoardNodeComponent,
    ProjectBoardArrowComponent,
    TimelineComponent,
    TimelineEventComponent,
    HUDComponent,
    AnalysisComponent,
    AnalysisContainerComponent,
    DateTimePickerComponent,
    SettingsDialogComponent,
    LiveChipComponent,
    ExploreChartComponent,
    ExploreCandlestickContainerComponent,
    InsightsLineChartComponent,
    InsightsCsChartComponent,
    ExploreDataDialogComponent,
    GridComponent,
    ModelChartWidgetComponent,
    StreamWidgetComponent,
    TickWidgetComponent,
    PacketWidgetComponent,
    MonacoWidgetComponent,
    ModelInputsWidgetComponent,
    StrategyWidgetComponent,
    StrategyEditorWidgetComponent,
    BreakoutWidgetComponent,
    BaseWidgetComponent,
    PaletteInputComponent,
    ExperimentsWidgetComponent,
    RawDataWidgetComponent,
    PromptsWidgetComponent,
    IndicatorWidgetComponent,
    FeatureSelectorWidgetComponent,
    SchemasWidgetComponent,
    BuildsWidgetComponent,
    AssetsWidgetComponent,
    DefinitionsWidgetComponent,
    VaultWidgetComponent,
    DeploysWidgetComponent,
    HostsWidgetComponent,
    GpusWidgetComponent,
    ProjectsWidgetComponent,
    PeopleWidgetComponent,
    TerminalWidgetComponent,
    InspectWidgetComponent,
    ChatWidgetComponent,
    SparklinesWidgetComponent,
    AgentWidgetComponent,
    IndicatorsWidgetComponent,
    WidgetTitlebarComponent,
    LabWidgetComponent,
    SettingsWidgetComponent,
    FormatToTitleCase,
    ProjectSelectorComponent,
    FileSelectorComponent,
    FeatureSelectorComponent,
    ModelSelectorComponent,
    PrettyDescriptionComponent,
    LastBuildSelectorComponent,
    VaultSelectorComponent,
    DeploySelectorComponent,
    InitComponent,
    QueryBuilderComponent,
    StreamsWidgetComponent,
    ServiceStatusIconComponent,
    OmnibarComponent,
    InfoSnackbarComponent,
    SettingsComponent,
    CompilerWidgetComponent,
    FormatEllipsize,
    StreamRestWidgetComponent,
    StreamApiStatsComponent,
    StreamApiLogsComponent,
    PerfTableWidgetComponent,
    ChatScreenComponent,
    ChatDialogComponent,
    SparklineChartComponent,
    DateRangePickerComponent,
    MenubarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SocketIoModule.forRoot(config),
    MaterialModule,
    GridsterModule,
    AngularSplitModule,
    MonacoEditorModule.forRoot(),
    KeycloakAngularModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    NgTerminalModule,
    NgxFileDropModule,
    NgxMatColorPickerModule,
    MatRippleModule,
    DragDropModule,
    MarkdownModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    DynamicModule,
    ScrollingModule,
    MasSplitButtonModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, InitService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSciChart,
      multi: true,
      deps: []
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: MAT_COLOR_FORMATS,
      useValue: NGX_MAT_COLOR_FORMATS
    },
    IdentityService,
    PaletteService,
    SettingsService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
