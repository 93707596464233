import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-monaco-widget',
  templateUrl: './monaco-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './monaco-widget.component.scss'],
})
export class MonacoWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @Input() path: any = null;
  @Input() title: string = null;

  content: string = null;
  originalContent: string = null; // To track changes
  hasUnsavedChanges: boolean = false;

  public editorOptions: any = {
    theme: 'vs-dark',
    language: 'plaintext', // Default language
    automaticLayout: true,
    wordWrap: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false,
    },
  };

  constructor(private fileService: FileService) {
    super();
  }

  ngOnInit(): void {
    // Detect language based on file extension
    this.editorOptions.language = this.detectLanguage(this.path);

    // Load the content from the file
    this.fileService.get(this.teamName, this.path).then((content) => {
      this.content = content;
      this.originalContent = content; // Set the original content for change tracking
    });

    // Add keyboard listener for Ctrl+S
    window.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  ngOnDestroy(): void {
    // Confirm unsaved changes before destroying the component
    if (this.hasUnsavedChanges && !this.confirmSaveChanges()) {
      return; // Prevent component destruction if they cancel
    }
    window.removeEventListener('keydown', this.handleKeydown.bind(this));
    super.ngOnDestroy();
  }

  handleKeydown(event: KeyboardEvent): void {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      event.preventDefault();
      this.saveContent();
    }
  }

  confirmSaveChanges(): boolean {
    const userConfirmed = confirm('You have unsaved changes. Do you want to save them before exiting?');
    if (userConfirmed) {
      this.saveContent();
    }
    return userConfirmed; // Allow destruction only if they confirm or save changes
  }

  saveContent(): void {
    if (this.content === this.originalContent) {
      console.log('No changes to save.');
      return;
    }

    this.fileService.postAsync(this.teamName, this.path, 'text', this.content).then(() => {
      console.log(`Saved content to ${this.path}`);
      this.originalContent = this.content; // Reset change tracking
      this.hasUnsavedChanges = false;
    });
  }

  onContentChange(): void {
    this.hasUnsavedChanges = this.content !== this.originalContent;
  }

  private detectLanguage(path: string): string {
    const extension = path.split('.').pop()?.toLowerCase();

    const languageMap: { [key: string]: string } = {
      js: 'javascript',
      ts: 'typescript',
      py: 'python',
      java: 'java',
      html: 'html',
      css: 'css',
      scss: 'scss',
      json: 'json',
      md: 'markdown',
      xml: 'xml',
      yml: 'yaml',
      yaml: 'yaml',
      cpp: 'cpp',
      c: 'c',
      cs: 'csharp',
      php: 'php',
      rb: 'ruby',
      go: 'go',
      rs: 'rust',
      sh: 'shell',
      txt: 'plaintext',
    };

    return languageMap[extension] || 'plaintext'; // Default to plaintext if no match
  }
}
