// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 100dvh;
  width: 100dvw;
}
:host.menubar-top {
  flex-direction: column;
}
:host.menubar-left {
  flex-direction: row;
}

gridster {
  background: transparent;
  overflow: hidden !important;
  flex-grow: 1;
}

gridster-item {
  border: 1px solid silver;
}
gridster-item .close-button {
  display: none;
}
gridster-item:hover .close-button {
  display: block;
}
gridster-item .titlebar {
  display: none;
}
gridster-item:hover .titlebar {
  display: block;
}

gridster-item-content {
  overflow: auto;
  overflow-x: hidden;
}

app-chat-widget {
  display: block;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/grid/grid.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,aAAA;AACF;AACE;EACE,sBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,uBAAA;EACA,2BAAA;EACA,YAAA;AADF;;AAIA;EACE,wBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,cAAA;AAJJ;;AAQA;EACE,cAAA;EACA,kBAAA;AALF;;AAQA;EACE,cAAA;EACA,YAAA;AALF","sourcesContent":[":host {\n  display: flex;\n  height: 100dvh;\n  width: 100dvw;\n\n  &.menubar-top {\n    flex-direction: column;\n  }\n\n  &.menubar-left {\n    flex-direction: row;\n  }\n}\n\ngridster {\n  background: transparent;\n  overflow: hidden !important;\n  flex-grow: 1;\n}\n\ngridster-item {\n  border: 1px solid silver;\n\n  & .close-button {\n    display: none;\n  }\n\n  &:hover .close-button {\n    display: block;\n  }\n\n  & .titlebar {\n    display: none;\n  }\n\n  &:hover .titlebar {\n    display: block;\n  }\n}\n\ngridster-item-content {\n  overflow: auto;\n  overflow-x: hidden;\n}\n\napp-chat-widget {\n  display: block;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
