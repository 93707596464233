import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItemComponent } from 'angular-gridster2';
import { ActivatedRoute } from '@angular/router';
import { IdentityService } from 'src/app/services/identity.service';
import { WidgetService } from 'src/app/services/widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ConfigService } from 'src/app/services/config.service';
import { SocketService } from 'src/app/services/socket.service';
import { ChatService } from 'src/app/services/chat.service';
import { SettingsService } from 'src/app/services/settings.service';

export namespace GridComponent {
  export type DashboardContainment = 'overlap' | 'contained' | 'outside';
}

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy {
  @HostBinding('class.menubar-top')
  private get _menubarTop() {
    return this.menubarPosition === 'top';
  }

  @HostBinding('class.menubar-left')
  private get _menubarLeft() {
    return this.menubarPosition === 'left';
  }

  private _maxRows = 1000;
  private _maxCols = 1000;
  private _socketSub: any;

  public options: GridsterConfig = {
    gridType: GridType.Fixed,
    displayGrid: DisplayGrid.None,
    compactType: CompactType.None,
    pushItems: true,
    draggable: {
      enabled: true,
      stop: this.$dragStop.bind(this),
      start: this.$dragStart.bind(this)
    },
    resizable: {
      enabled: true,
      stop: this.$resizeStop.bind(this),
      start: this.$resizeStart.bind(this)
    },
    itemResizeCallback: this.$resize.bind(this),
    margin: 0,
    fixedColWidth: 20,
    fixedRowHeight: 20,
    minCols: 50,
    maxCols: this._maxCols,
    minRows: 50,
    maxRows: this._maxRows,
    maxItemCols: this._maxCols,
    minItemCols: 1,
    maxItemRows: this._maxRows,
    minItemRows: 1,
    maxItemArea: this._maxRows * this._maxCols,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    allowMultiLayer: true,
    addEmptyRowsCount: 2
  };
  public teamName: string;
  public isOpen = { board: false, devices: false, data: false, models: false, streams: false };
  public chatEnabled = true;
  public lastShowMeTime: any = new Date();
  public lastShowMe = null;

  private _nextLayerIndex = 0;
  get nextLayerIndex(): number {
    this._nextLayerIndex = Math.max(this._nextLayerIndex, ...this.dashboard.map((x) => x.layerIndex)) + 1;
    return this._nextLayerIndex;
  }

  public get dashboard() {
    return this._widget.widgets;
  }

  public get menubarPosition() {
    return this._settings.menubarPosition;
  }

  /**
   * Creates a Widget, adds the widget to the grid, and then saves the grid.
   * @param name The name of the widget to add
   * @param values The raw metadata associated with the widget
   */
  private _addWidget(
    name: WidgetService.WidgetName,
    values?: Partial<WidgetService.Widget>,
    config?: Partial<{
      defaultSpace: WidgetService.GridSpace;
      baseInputs: Record<string, any>;
      extraOutputs: Record<string, any>;
    }>
  ) {
    const onClose = this.closeWidget.bind(this);
    const onRestore = this.restoreWidget.bind(this);
    const onMaximize = this.maximizeWidget.bind(this);
    const onToggle = this.toggleWidget.bind(this);

    const widget = this._widget.createWidget(name, this.teamName, values, {
      defaultSpace: config?.defaultSpace,
      baseInputs: config?.baseInputs,
      outputs: { ...(config?.extraOutputs ?? {}), onClose, onRestore, onMaximize, onToggle }
    });
    widget.isMaximized = values?.isMaximized;

    this._widget.addWidget(this.teamName, widget);
  }

  private _removeWidget(...ids: string[]) {
    // use setTimeout, otherwise gridster leaves gridster-preview behind
    setTimeout(() => this._widget.removeWidget(this.teamName, ...ids));
  }

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _widget: WidgetService,
    public readonly identity: IdentityService,
    private _socket: SocketService,
    private readonly _settings: SettingsService
  ) {
    (<any>window).grid = this;
    this.chatEnabled = ConfigService.features?.chat != false;
  }

  public ngOnInit(): void {
    this._widget.registerGrid(this);

    this._activatedRoute.params.subscribe((params) => {
      this.teamName = params.team;
      this.loadDashboard();
    });
    this._widget.restore$.subscribe(() => {
      this.options.api.optionsChanged();
    });
    this._widget.maximize$.subscribe(() => {
      this.options.api.optionsChanged();
    });
    this._socketSub = this._socket.subscribeToRoomMessages('showme').subscribe((response) => {
      const now: any = new Date();
      if (this.identity.me.username !== response.username) {
        return;
      }
      this.lastShowMe = response.component.toLowerCase();
      this.lastShowMeTime = now;

      const component = response.component.toLowerCase();
      if (['experiments', 'training'].includes(component)) {
        this.addExperimentsWidget();
      } else if (['raw', 'upload', 'uploads', 'data'].includes(component)) {
        this.addRawDataWidget();
      } else if (['explore', 'features'].includes(component)) {
        this.addFeatureSelectorWidget();
      } else if (component == 'schemas') {
        this.addSchemasWidget();
      } else if (component == 'builds') {
        this.addBuildsWidget();
      } else if (['jupyterlab', 'jupyter', 'lab'].includes(component)) {
        this.addLabWidget();
      } else if (['audit', 'assets'].includes(component)) {
        this.addAssetsWidget();
      } else if (['design', 'canvas'].includes(component)) {
        const filter = 'filter' in response ? response.filter : null;
        this.addDefinitionsWidget(filter, null);
      } else if (component == 'vault') {
        const vaultName = 'vaultName' in response ? response.vaultName : null;
        this.addVaultWidget(vaultName);
      } else if (['deploys', 'services'].includes(component)) {
        this.addDeploysWidget();
      } else if (['hosts', 'machines'].includes(component)) {
        this.addHostsWidget();
      } else if (['compute', 'devices', 'gpus'].includes(component)) {
        this.addGPUsWidget();
      } else if (component == 'settings') {
        this.addSettingsWidget();
      } else if (component === 'stream') {
        const streamName = 'streamName' in response ? response.streamName : null;
        const modelName = 'modelName' in response ? response.modelName : null;
        if (!streamName) return;

        this.addStreamWidget(streamName, null, null, modelName);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._socketSub) this._socketSub.unsubscribe();
  }

  public findNestedIframe(collection: any[]): HTMLIFrameElement | undefined {
    for (let i = 0; i < collection.length; i++) {
      const element = collection[i];

      if (element.tagName === 'IFRAME') return element;

      if (element.children.length > 0) {
        const nestedIframe = this.findNestedIframe(element.children);
        if (nestedIframe) return nestedIframe;
      }
    }
    return undefined;
  }

  public preventIFrameCollision(itemComponent: GridsterItemComponent, pointerEvents: boolean = false): void {
    const children = itemComponent.el.children;
    const widget = Array.from(children).find((e) => e.nodeName.endsWith('-WIDGET')) as HTMLDivElement | undefined;
    if (!widget) return;

    const iframe = this.findNestedIframe(Array.from(widget.children));
    if (!iframe) return;

    iframe.style.pointerEvents = pointerEvents ? 'initial' : 'none';
  }

  public findContainment(a: WidgetService.Widget, b: WidgetService.Widget): GridComponent.DashboardContainment {
    // adapted from: https://stackoverflow.com/a/59498518/61396
    function toRect(item) {
      return {
        left: item.x,
        right: item.x + item.cols,
        top: item.y,
        bottom: item.y + item.rows
      };
    }

    const aRect = toRect(a);
    const bRect = toRect(b);

    if (
      /* Does container left or right edge pass through element? */
      (aRect.left < bRect.left && aRect.right > bRect.left) ||
      (aRect.left < bRect.right && aRect.right > bRect.right) ||
      /* Does container top or bottom edge pass through element? */
      (aRect.top < bRect.top && aRect.bottom > bRect.top) ||
      (aRect.top < bRect.bottom && aRect.bottom > bRect.bottom)
    ) {
      return 'overlap';
    }

    /*
    If boundaries of element fully contained inside bounday of
    container, classify this as containment of element in container
    */
    if (aRect.left >= bRect.left && aRect.top >= bRect.top && aRect.bottom <= bRect.bottom && aRect.right <= bRect.right) {
      return 'contained';
    }

    /*
    Otherwise, the element is fully outside the container
    */
    return 'outside';
  }

  public loadDashboard(): void {
    try {
      const storageItem = localStorage.getItem(`${this.teamName}-widgets`);
      if (!storageItem || storageItem.length === 0) return;

      const dashboard = JSON.parse(storageItem) as WidgetService.Widget[];
      if (!dashboard) return;

      for (let item of dashboard) {
        switch (item.name) {
          case 'RestStreamWidget':
            this.addStreamRestWidget(item.inputs.streamName, item.inputs.activeIndicators, item);
            break;
          case 'StreamWidget':
            this.addStreamWidget(item.inputs.streamName, item.inputs.activeIndicators, item);
            break;
          case 'StreamsWidget':
            this.addStreamsWidget(item);
            break;
          case 'ModelChartWidget':
            this.addModelWidget(
              item.inputs.streamName,
              item.inputs.modelName,
              item.inputs.parentId,
              item.inputs.ticker,
              item.inputs.interval,
              item.inputs.parentChartData,
              item
            );
            break;
          case 'TickWidget':
            this.addTickWidget(item.inputs.streamName, item.inputs.symbol, item.inputs.parentId, item.inputs.date);
            break;
          case 'PacketWidget':
            this.addPacketWidget(item.inputs.parentId, item.inputs.packetContent, item);
            break;
          case 'MonacoWidget':
            this.addMonacoWidget(item.inputs.parentId, item.inputs.path, item);
            break;
          case 'ModelInputsWidget':
            this.addModelInputWidget(item.inputs.parentId, item.inputs.timestamp, item.inputs.modelName, item.inputs.modelValues, item);
            break;
          case 'StrategyWidget':
            this.addStrategyWidget(item.inputs.parentId, item.inputs.modelName, item.inputs.ticker, item.inputs.strats, item);
            break;
          case 'StrategyEditorWidget':
            this.addStrategyEditorWidget(item.inputs.parentId, item.inputs.modelName, item.inputs.name, item);
            break;
          case 'BreakoutWidget':
            this.addBreakoutWidget(
              item.inputs.parentId,
              item.inputs.modelName,
              item.inputs.ticker,
              item.inputs.strategyName,
              item.input.days,
              item
            );
            break;
          case 'ExperimentsWidget':
            this.addExperimentsWidget(item.inputs.definitionName, item);
            break;
          case 'PerfTableWidget':
            this.addPerfTableWidget(item.run, item.displayedColumns, item.dynamicColumns, item.live, item);
            break;
          case 'PromptsWidget':
            this.addPromptsWidget(item);
            break;
          case 'IndicatorsWidget':
            this.addIndicatorsWidget(item);
            break;
          case 'RawDataWidget':
            this.addRawDataWidget(item);
            break;
          case 'FeatureSelectorWidget':
            this.addFeatureSelectorWidget(item);
            break;
          case 'SchemasWidget':
            this.addSchemasWidget(item);
            break;
          case 'BuildsWidget':
            this.addBuildsWidget(item);
            break;
          case 'LabWidget':
            this.addLabWidget(item);
            break;
          case 'AssetsWidget':
            this.addAssetsWidget(item);
            break;
          case 'InspectWidget':
            this.addInspectWidget(item.inputs.parentId, item.inputs.type, item.inputs.assetName, item.inputs.fileName, item);
            break;
          case 'DefinitionsWidget':
            this.addDefinitionsWidget(item.inputs.filter, item);
            break;
          case 'VaultWidget':
            this.addVaultWidget(item.inputs.name, item);
            break;
          case 'DeploysWidget':
            this.addDeploysWidget(item);
            break;
          case 'HostsWidget':
            this.addHostsWidget(item);
            break;
          case 'TerminalWidget':
            this.addTerminalWidget(item.command, item.title, item);
            break;
          case 'GPUsWidget':
            this.addGPUsWidget(item);
            break;
          case 'ChatWidget':
            this.addChatWidget(item.inputs.messages, item.inputs.disabled, item);
            break;
          case 'SparklinesWidget':
            this.addSparklinesWidget(item);
            break;
          case 'AgentWidget':
            this.addAgentWidget(item.inputs.model, item.inputs.ticker, item.inputs.interval, item.inputs.presetDateRange, item);
            break;
          case 'SettingsWidget':
            this.addSettingsWidget(item);
            break;
          case 'CompilerWidget':
            this.addCompilerWidget(item.inputs.parentId, item.inputs.rdsName, item);
            break;
        }
      }
    } catch {}
  }

  public addStreamWidget(streamName: string, activeIndicators = [], values = null, modelName = null, ticker = null): void {
    this._addWidget('StreamWidget', values, {
      defaultSpace: { rows: 25, cols: 50 },
      baseInputs: { streamName, activeIndicators, modelName, ticker }, // Add ticker here
      extraOutputs: {
        onModelSelect: this.$modelSelect.bind(this),
        onIndicatorSelect: this.$indicatorSelect.bind(this),
        onCloseChildren: this.$closeChildren.bind(this),
        onTickSelect: this.$tickSelect.bind(this),
        onStrategy: this.$strategy.bind(this)
      }
    });
  }

  public addStreamRestWidget(streamName: string, activeIndicators = [], values = null, modelName = null): void {
    this._addWidget('RestStreamWidget', values, {
      defaultSpace: { rows: 25, cols: 50 },
      baseInputs: { name: streamName },
      extraOutputs: { onIFrameFocus: this.$iFrameFocus.bind(this) }
    });
  }

  public addStreamsWidget(values = null): void {
    this._addWidget('StreamsWidget', values, {
      extraOutputs: {
        onStreamSelect: this.$streamSelect.bind(this)
      }
    });
  }

  public addModelWidget(
    streamName: string,
    modelName: string,
    parentId: string,
    ticker: string,
    interval: string,
    parentChartData: any[],
    values = null
  ): void {
    const parent = this.dashboard.find(({ id }) => id === parentId);

    this._addWidget('ModelChartWidget', values, {
      defaultSpace: { x: parent.x, y: parent.y + parent.rows, rows: 15, cols: parent.cols },
      baseInputs: { parentId, streamName, modelName, ticker, interval, parentChartData },
      extraOutputs: { onPredictSelect: this.$predictSelect.bind(this), onStrategyEditor: this.$strategyEditor.bind(this) }
    });
  }

  public addIndicatorWidget(
    streamName: string,
    parentId: string,
    ticker: string,
    indicator: any,
    parentChartData: any[],
    values = null
  ): void {
    const parent = this.dashboard.find(({ id }) => id === parentId);

    this._addWidget('IndicatorWidget', values, {
      defaultSpace: { x: parent.x, y: parent.y + parent.rows, rows: 15, cols: parent.cols },
      baseInputs: { parentId, streamName, indicator, ticker, parentChartData }
    });
  }

  public addTickWidget(streamName: string, symbol: string, parentId: string, date: Date, values = null): void {
    this._addWidget('TickWidget', values, {
      defaultSpace: { y: 3, rows: 20, cols: 50 },
      baseInputs: { parentId, streamName, symbol, date },
      extraOutputs: { onPacketSelect: this.$packetSelect.bind(this) }
    });
  }

  public addPacketWidget(parentId: string, packetContent: any, values = null): void {
    this._addWidget('PacketWidget', values, {
      defaultSpace: { y: 4, rows: 20, cols: 40 },
      baseInputs: { parentId, packetContent },
      extraOutputs: { onPacketSelect: this.$packetSelect.bind(this) }
    });
  }

  public addMonacoWidget(parentId: string, path: any, values = null): void {
    this._addWidget('MonacoWidget', values, {
      defaultSpace: { y: 1, rows: 40, cols: 40 },
      baseInputs: { parentId, path },
      extraOutputs: {}
    });
  }

  public addModelInputWidget(parentId: string, timestamp: string, modelName: string, modelValues: any, values = null): void {
    this._addWidget('ModelInputsWidget', values, {
      baseInputs: { parentId, timestamp, modelName, modelValues },
      extraOutputs: { onPredictSelect: this.$predictSelect.bind(this) }
    });
  }

  public addStrategyWidget(parentId: string, modelName: string, ticker: string, strats: string[], values = null): void {
    this._addWidget('StrategyWidget', values, {
      defaultSpace: { rows: 23, cols: 50 },
      baseInputs: { parentId, modelName, ticker, strats },
      extraOutputs: { onLoadBreakout: this.$loadBreakout.bind(this) }
    });
  }

  public addStrategyEditorWidget(parentId: string, modelName: string, name: string, values = null): void {
    this._addWidget('StrategyEditorWidget', values, {
      defaultSpace: { rows: 23, cols: 50 },
      baseInputs: { parentId, modelName, name },
      extraOutputs: {}
    });
  }

  public addBreakoutWidget(parentId: string, modelName: string, ticker: string, strategyName, days, values = null): void {
    this._addWidget('BreakoutWidget', values, {
      defaultSpace: { x: 4, y: 2, rows: 30, cols: 40 },
      baseInputs: { parentId, modelName, ticker, strategyName, days }
    });
  }

  public addExperimentsWidget(definitionName: string = null, values = null): void {
    this._addWidget('ExperimentsWidget', values, {
      baseInputs: { definitionName },
      extraOutputs: {
        onProcessView: this.$terminal.bind(this),
        onVaultItem: this.$viewVaultDetail.bind(this),
        onPerfTableView: this.$perfTableView.bind(this)
      }
    });
  }

  public addPerfTableWidget(run: any, displayedColumns: string[], dynamicColumns: any[], live: boolean, values = null) {
    this._addWidget('PerfTableWidget', values, {
      baseInputs: { run, displayedColumns, dynamicColumns, live }
    });
  }

  public addRawDataWidget(values = null): void {
    this._addWidget('RawDataWidget', values);
  }

  public addPromptsWidget(values = null): void {
    this._addWidget('PromptsWidget', values, {
      extraOutputs: {
        onEditFile: this.$editFile.bind(this)
      }
    });
  }

  public addIndicatorsWidget(values = null): void {
    this._addWidget('IndicatorsWidget', values, {
      extraOutputs: {
        onEditFile: this.$editFile.bind(this)
      }
    });
  }

  public addFeatureSelectorWidget(values = null): void {
    this._addWidget('FeatureSelectorWidget', values, {
      extraOutputs: {
        onEditFile: this.$editFile.bind(this),
        onPacketSelect: this.$packetSelect.bind(this)
      }
    });
  }

  public addSchemasWidget(values = null): void {
    this._addWidget('SchemasWidget', values, {
      extraOutputs: { onBuild: this.$viewBuild.bind(this) }
    });
  }

  public addBuildsWidget(values = null): void {
    const inputs = values && Object.keys(values).includes('build') ? { build: values['build'] } : null;
    this._addWidget('BuildsWidget', values, {
      baseInputs: inputs
    });
  }

  public addAssetsWidget(values = null): void {
    const inputs = values && Object.keys(values).includes('assetName') ? { assetName: values['assetName'] } : null;
    this._addWidget('AssetsWidget', values, {
      baseInputs: inputs,
      extraOutputs: { onDefinition: this.$definition.bind(this) }
    });
  }

  public addInspectWidget(parentId: string, type: string, assetName: string, fileName: string, values = null) {
    this._addWidget('InspectWidget', values, {
      baseInputs: { parentId, type, assetName, fileName }
    });
  }

  public addDefinitionsWidget(filter: string = null, values = null): void {
    this._addWidget('DefinitionsWidget', values, {
      baseInputs: { filter },
      extraOutputs: {
        onViewAsset: this.$asset.bind(this),
        onViewExperiments: this.$experiments.bind(this),
        onEditFile: this.$editFile.bind(this),
        onCompile: this.$compile.bind(this)
      }
    });
  }

  public addVaultWidget(vaultName?: string, values = null): void {
    this._addWidget('VaultWidget', values, {
      baseInputs: { vaultName }
    });
  }

  public addDeploysWidget(values = null): void {
    this._addWidget('DeploysWidget', values);
  }

  public addHostsWidget(values = null): void {
    this._addWidget('HostsWidget', values, {
      extraOutputs: { onTerminal: this.$terminal.bind(this) }
    });
  }

  public addTerminalWidget(command: string, title: string, values = null): void {
    this._addWidget('TerminalWidget', values, {
      baseInputs: { command, title }
    });
  }

  public addGPUsWidget(values = null): void {
    this._addWidget('GPUsWidget', values);
  }

  public addLabWidget(values = null): void {
    this._addWidget('LabWidget', values);
  }

  public addSparklinesWidget(values = null): void {
    this._addWidget('SparklinesWidget', values);
  }

  public addAgentWidget(
    model: string | null,
    ticker: string | null,
    interval: string | null,
    presetDateRange: string | null,
    values = null
  ): void {
    this._addWidget('AgentWidget', values, {
      baseInputs: {
        model,
        ticker,
        interval,
        presetDateRange
      },
      extraOutputs: {
        onEditFile: this.$editFile.bind(this),
        onStrategyEditor: this.$strategyEditor.bind(this)
      }
    });
  }

  public addChatWidget(messages: ChatService.ChatMessage[], disabled: boolean, values = null): void {
    this._addWidget('ChatWidget', values, {
      baseInputs: { messages: messages ?? [], disabled: disabled ?? false },
      defaultSpace: { minItemCols: 20, minItemRows: 20, cols: 25, rows: 35 },
      extraOutputs: {
        onViewExperiments: this.$experiments.bind(this),
        onViewDefinition: this.$definition.bind(this),
        onViewBuild: this.$viewBuild.bind(this),
        onViewAsset: this.$viewAsset.bind(this),
        onViewFeatureExplorer: this.$featureExplorer.bind(this),
        onViewVault: this.$viewVault.bind(this),
        onViewUpload: this.$upload.bind(this)
      }
    });
  }

  public addSettingsWidget(values = null): void {
    this._addWidget('SettingsWidget', values, { defaultSpace: { minItemCols: 27, rows: 35, cols: 55 } });
  }

  public addCompilerWidget(parentId: string, rdsName: string, values = null): void {
    this._addWidget('CompilerWidget', values, {
      baseInputs: { parentId, rdsName },
      defaultSpace: { minItemCols: 41, minItemRows: 25 }
    });
  }

  public closeWidget($event: BaseWidgetComponent.Event): void {
    this._removeWidget($event.id);
  }

  public restoreWidget($event: BaseWidgetComponent.Event): void {
    this._widget.restoreWidget(this.teamName, $event.id);
  }

  public maximizeWidget($event: BaseWidgetComponent.Event): void {
    this._widget.maximizeWidget(this.teamName, $event.id);
  }

  public toggleWidget($event: BaseWidgetComponent.Event): void {
    this._widget.toggleWidget(this.teamName, $event.id);
  }

  public openSettings(): void {
    const widget = this._widget.createWidget('SettingsWidget', this.teamName ?? '', undefined, {
      defaultSpace: { minItemRows: 16, minItemCols: 27 },
      outputs: {
        onClose: ($event: BaseWidgetComponent.Event) => {
          // use setTimeout, otherwise gridster leaves gridster-preview behind
          setTimeout(() => this._widget.removeWidget(this.identity.me.selectedTeamName, $event.id));
        },
        onRestore: ($event: BaseWidgetComponent.Event) => {
          this._widget.restoreWidget(this.identity.me.selectedTeamName, $event.id);
        },
        onMaximize: ($event: BaseWidgetComponent.Event) => {
          this._widget.maximizeWidget(this.identity.me.selectedTeamName, $event.id);
        },
        onToggle: ($event: BaseWidgetComponent.Event) => {
          this._widget.toggleWidget(this.identity.me.selectedTeamName, $event.id);
        }
      }
    });
    this._widget.addWidget(this.identity.me.selectedTeamName, widget);
  }

  public $closeChildren(children: string[]): void {
    this._removeWidget(...children);
  }

  public $modelSelect(input: any): void {
    this.addModelWidget(input.streamName, input.modelName, input.parentId, input.ticker, input.interval, input.parentChartData);
  }

  public $indicatorSelect(input: any): void {
    const found = this._widget.widgets.find((widget) => widget.id === input.parentId);
    if (!found) return;

    found.inputs.activeIndicators = input.activeIndicators;
    this._widget.updateWidget(this.teamName, input.parentId, found);
  }

  public $tickSelect(input: any): void {
    this.addTickWidget(input.streamName, input.ticker, input.parentId, input.date);
  }

  public $editFile(input: any): void {
    this.addMonacoWidget(input.parentId, input.path);
  }

  public $packetSelect(input: any): void {
    this.addPacketWidget(input.parentId, input.packetContent);
  }

  public $predictSelect(input: any): void {
    this.addModelInputWidget(input.parentId, input.timestamp, input.modelName, input.modelValues);
  }

  public $strategy(input: any): void {
    this.addStrategyWidget(input.parentId, input.modelName, input.ticker, input.strats);
  }

  public $strategyEditor(input: any): void {
    this.addStrategyEditorWidget(input.parentId, input.modelName, input.name);
  }

  public $loadBreakout(input: any): void {
    this.addBreakoutWidget(input.parentId, input.modelName, input.ticker, input.strategyName, input.days);
  }

  public $terminal(input: any): void {
    const command = input.command ? input.command : null;
    const title = input.title ? input.title : null;
    this.addTerminalWidget(command, title);
  }

  public $lab(input: any): void {
    this.addLabWidget(input.parentId);
  }

  public $inspect(input: any) {
    this.addInspectWidget(input.parentId, input.type, input.assetName, input.fileName);
  }

  public $experiments(input: any) {
    this.addExperimentsWidget(input.projectName);
  }

  public $definition(input: any) {
    this.addDefinitionsWidget(input.definitionName);
  }

  public $viewBuild(input: any) {
    this.addBuildsWidget(input);
  }

  public $viewAsset(input: any) {
    this.addAssetsWidget(input.assetName);
  }

  public $featureExplorer(input: any) {
    this.addFeatureSelectorWidget(input.projectName);
  }

  public $viewVault(input: any) {
    this.addVaultWidget(input.projectName);
  }

  public $viewVaultDetail(input: any) {
    this.addVaultWidget(input.vaultName);
  }

  public $asset(input: any): void {
    this.addAssetsWidget();
  }

  public $upload() {
    this.addRawDataWidget();
  }

  public $streamSelect(input: any) {
    if (input.ui == 'rest') {
      this.addStreamRestWidget(input.streamName);
    } else {
      this.addStreamWidget(input.streamName);
    }
  }

  public $compile(input: any) {
    this.addCompilerWidget(input.parentId, input.rdsName);
  }

  public $perfTableView(input: any) {
    this.addPerfTableWidget(input.run, input.displayedColumns, input.dynamicColumns, input.live);
  }

  public $iFrameFocus(input: any) {
    const item = this.dashboard.find((x) => x.id === input.id);
    if (!item) return;

    if (item.layerIndex <= this._nextLayerIndex || this._nextLayerIndex === 0) {
      item.layerIndex = this.nextLayerIndex;
      this._widget.updateWidget(this.teamName, item.id, item);
    }
  }

  public $openWidget(widgetName: WidgetService.WidgetName): void {
    switch (widgetName) {
      case 'AgentWidget':
        this.addAgentWidget(undefined, undefined, undefined, undefined, undefined);
        break;
      case 'PromptsWidget':
        this.addPromptsWidget();
        break;
      case 'HostsWidget':
        this.addHostsWidget();
        break;
      case 'GPUsWidget':
        this.addGPUsWidget();
        break;
      case 'RawDataWidget':
        this.addRawDataWidget();
        break;
      case 'FeatureSelectorWidget':
        this.addFeatureSelectorWidget();
        break;
      case 'BuildsWidget':
        this.addBuildsWidget();
        break;
      case 'AssetsWidget':
        this.addAssetsWidget();
        break;
      case 'LabWidget':
        this.addLabWidget();
        break;
      case 'DefinitionsWidget':
        this.addDefinitionsWidget();
        break;
      case 'ExperimentsWidget':
        this.addExperimentsWidget();
        break;
      case 'VaultWidget':
        this.addVaultWidget();
        break;
      case 'StreamWidget':
        this.addStreamWidget(null);
        break;
      case 'IndicatorsWidget':
        this.addIndicatorsWidget();
        break;
      case 'SparklinesWidget':
        this.addSparklinesWidget();
        break;
      case 'StreamsWidget':
        this.addStreamsWidget();
        break;
      case 'DeploysWidget':
        this.addDeploysWidget();
        break;
      case 'SettingsWidget':
        this.openSettings();
        break;
      default:
        console.warn(`unknown widget: "${widgetName}"`);
        break;
    }
  }

  $click(_$event, item: WidgetService.Widget): void {
    this.dashboard.forEach((dashboardItem) => {
      if (dashboardItem.id == item.id) {
        return;
      }
      const containment = this.findContainment(item, dashboardItem);
      if (containment != 'outside' && dashboardItem.layerIndex > item.layerIndex) {
        const now: any = new Date();
        if (!dashboardItem.birthday || now - dashboardItem.birthday.getTime() > 1000) {
          item.layerIndex = this.nextLayerIndex;
        }
      }
    });
  }

  public $dragStop(item: WidgetService.Widget, itemComponent: GridsterItemComponent, $event): void {
    this.preventIFrameCollision(itemComponent, true);
    setTimeout(() => this._widget.updateWidget(this.teamName, item.id, item), 100);
  }

  public $dragStart(item: WidgetService.Widget, itemComponent: GridsterItemComponent, _$event): void {
    this.preventIFrameCollision(itemComponent);
  }

  public $resizeStop(item: WidgetService.Widget, itemComponent: GridsterItemComponent, _$event): void {
    this.preventIFrameCollision(itemComponent, true);
  }

  public $resizeStart(item: WidgetService.Widget, itemComponent: GridsterItemComponent, _$event): void {
    this.preventIFrameCollision(itemComponent);
  }

  public $resize(item: WidgetService.Widget, itemComponent: GridsterItemComponent): void {
    this._widget.resize$.next({ item, itemComponent });
    setTimeout(() => this._widget.updateWidget(this.teamName, item.id, item), 100);

    const event = new Event(`resize:${item.id}`);
    window.dispatchEvent(event);
  }
}
