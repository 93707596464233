import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IndicatorsService } from 'src/app/services/indicators.service';

@Component({
  selector: 'app-indicators-widget',
  templateUrl: './indicators-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './indicators-widget.component.scss']
})
export class IndicatorsWidgetComponent extends BaseWidgetComponent implements OnInit, AfterViewInit {

  @Output() onEditFile = new EventEmitter<{ path: any }>();

  public path: string;
  public fileType: string;
  public title: string = null;
  public selectedDataset: string = null;
  public filterText = null;
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns: string[] = ['name'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('indicatorsTable') indicatorsTable!: ElementRef;
  public pageSize = 10;

  public right = 80;
  public left = 20;
  public isLoading: boolean = true;

  constructor(
    private _widgetService: WidgetService,
    private _indicators: IndicatorsService,
    private _elRef: ElementRef
  ) {
    super();
    if ((<any>window).indicators) {
      (<any>window).indicators.push(this);
    } else {
      (<any>window).indicators = [this];
    }
  }

  ngOnInit(): void {
    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      setTimeout(() => {
        try {
          this.optimizePageSize();
        } catch {}
      }, 100);
    });

    this._indicators.load(this.teamName).subscribe((response) => {
      const data = <any[]>response;
      const unique = [...new Set(data.map(x => x.path ))];
      this.dataSource.data = unique.map( x => { return { name: x.replace(/^.*[\\/]/, ''), path: x }});
      this.optimizePageSize();
      this.isLoading = false;
    });

  }

  public ngAfterViewInit(): void {
    this.applyTableSettings();

  }

  applyTableSettings() {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.optimizePageSize();
    }, 0);
  }

  public optimizePageSize(): void {
    let offsetHeight = this._elRef.nativeElement.offsetHeight;

    const oldPageSize = this.pageSize;
    const calcuatedPageSize = Math.floor(0.0125 * offsetHeight);
    if (calcuatedPageSize < 5) {
      this.pageSize = 5;
    } else {
      this.pageSize = calcuatedPageSize;
    }

    if (oldPageSize !== this.pageSize) {
      setTimeout(() => this.paginator?._changePageSize(this.pageSize), 100);
    }
  }


  onViewData(input: any) {
    this.path = input.path;
    this.fileType = input.fileType;
    this.title = input.path.split('/').pop();
    this.widgetView = 'DETAIL';
    this.right = 100;
    this.left = 0;
  }

  onSelect(item: any) {
    const path = item.path;
    this.onEditFile.emit({ path });
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }


  close() {
    if (this.widgetView === 'DETAIL') {
      this.path = null;
      this.fileType = null;
      this.title = null;
      this.widgetView = 'LIST';
      this.right = 80;
      this.left = 20;

    } else {
      super.close();
    }
  }

  async onDeleteDataset($event, item) {
    if (!confirm(`Are you sure want to delete indicator ${item}?`)) {
      return;
    }

    $event.stopPropagation();
    this.onSelect(null);
  }

}
