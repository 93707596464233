import { Component, ViewChild, OnDestroy, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnInit, OnDestroy {
  @Output() chipClick = new EventEmitter();
  @Output() chipAdded = new EventEmitter<{ name: string }>();
  @Output() chipRemoved = new EventEmitter<{ name: string }>();
  @Output() chipReordered = new EventEmitter<{ previousIndex: number; currentIndex: number }>();
  @Input() public label: string = '';
  @Input() public value = [];
  @Input() public all = [];

  public get available(): string[] {
    return this.all.filter(x => !this.value.includes(x));
  }

  public formCtrl = new FormControl('');
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor() {}

  ngOnInit(): void {}

  onClick(event) {
    this.chipClick.emit(event);
  }

  ngOnDestroy(): void {}

  async add(event: MatAutocompleteSelectedEvent) {
    const chipName = event.option.viewValue;
    if (!this.value.includes(chipName)) {
      this.value.push(chipName);
      this.chipAdded.emit({ name: chipName });
    }
    this.input.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  delete(item) {
    this.value = this.value.filter(x => x !== item);
    this.chipRemoved.emit({ name: item });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.value, event.previousIndex, event.currentIndex);
    this.chipReordered.emit({ previousIndex: event.previousIndex, currentIndex: event.currentIndex });
  }
}
