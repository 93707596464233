import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
import { WidgetService } from 'src/app/services/widget.service';
import { StratsService } from 'src/app/services/strats';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { IndicatorsService } from 'src/app/services/indicators.service';
import { QueryBuilderConfig } from '../../query-builder/query-builder.interfaces';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-strategy-editor-widget',
  templateUrl: './strategy-editor-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './strategy-editor-widget.component.scss']
})
export class StrategyEditorWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @Input() modelName: string;
  @Input() name: string[];

  public availableModels: ChatService.AvailableModel[] = [];
  model: string = 'llama3.1:latest';
  messages: ChatService.ChatMessage[] = [];

  private resizeObserver: ResizeObserver;
  public codeEditorOptions = {
    theme: 'vs-dark',
    language: 'python',
    automaticLayout: true,
    wordWrap: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };

  public inputEditorOptions = {
    theme: 'vs-dark', // Use a light theme for differentiation
    language: 'json',  // Syntax highlighting for JSON
    wordWrap: true,
    readOnly: true,    // Makes the editor readonly
    automaticLayout: true, // Adjust layout dynamically
    minimap: {
      enabled: false // Optional: disable minimap for cleaner display
    }
  };


  public strategies = [];
  public selectedStrategy = null;
  private codeSubject = new Subject<string>();
  strategyPath: string = null;

  private _query = {
    condition: 'and',
    rules: [
      {
        condition: 'and',
        rules: [
          { field: 'ago', operator: '=', value: 1 },
          { field: 'timespan', operator: '=', value: 'minute' },
          { field: 'prediction', operator: '>=', value: 0.5 }
        ]
      },
      {
        condition: 'and',
        rules: [
          { field: 'ago', operator: '=', value: 2 },
          { field: 'timespan', operator: '=', value: 'minute' },
          { field: 'pullback', operator: '>=', value: 0.5 }
        ]
      }
    ]
  };

  public get query(): object {
    return this._query;
  }

  public set query(v: object) {
    const oldValue = this._query;
    this._query = <any>v;
    if (v == null || oldValue == null) {
      return;
    }

    const newEncodedValue = JSON.stringify(v, null, '\t');
    if (this.selectedStrategy.lastSavedValue !== null && newEncodedValue !== this.selectedStrategy.lastSavedValue) {
      console.log(`Saving ${this.selectedStrategy.path}`);
      this.fileService.postAsync(this.teamName, this.selectedStrategy.path, 'json', v);
      this.selectedStrategy.lastSavedValue = newEncodedValue;
      this._widgetService.stratEdit$.next({ parentId: this.parentId });
    }
  }

  // Sourced from: https://pixabay.com/sound-effects/search/notification/
  // "Royalty-free notification sound effects."
  alerts = [
    { name: 'Stop', value: 'stop-13692' },
    { name: 'Notification', value: 'notification-sound-7062' },
    { name: 'Email', value: 'the-notification-email-143029' },
    { name: 'Announcement', value: 'announcement-sound-4-21464' },
    { name: 'Message', value: 'message-ringtone-21467' },
    { name: 'Cash Register', value: 'cash-register-kaching-sound-effect-125042' }
  ];

  config: QueryBuilderConfig = {
    fields: {
      cooldown: {
        name: 'Cooldown (mins)',
        type: 'number',
        operators: ['<', '<=']
      },
      prediction: { name: 'Prediction', type: 'number' },
      Volume: { name: 'Volume', type: 'number' },
      Trades: { name: 'Trades', type: 'number' },
      spike: {
        name: 'Spike',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      flat: {
        name: 'Flat',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      flat_abs: {
        name: 'Flat (abs)',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      pullback: {
        name: 'Pullback (%)',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      ago: {
        name: 'Minutes Ago',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      marker: {
        name: 'Marker',
        type: 'category',
        options: [
          { name: 'Up', value: 'up' },
          { name: 'Down', value: 'down' }
        ]
      },
      color: {
        name: 'Color',
        type: 'string',
        operators: ['==']
      },
      alert: {
        name: 'Alert',
        type: 'category',
        options: this.alerts
      }
    }
  };

  strategyType = 'querybuilder';
  showCode: boolean = false;
  code: string = null;
  input: string = null;
  public hiddenStrategyList = new SelectionModel<any>(true, []);

  public systemPrompt: string = "You are a financial assistant analyzing stock data.";
  public finalPrompt: string = "Analyze the following stock data...\n\n{data_json}";
  public selectedPrompt: { type: 'system' | 'final' | 'analysis'; index?: number } = { type: 'system' };
  public selectedAnalysisIndex: number = 0; // Keeps track of the currently selected tab
  public analyses: { name: string; prompt: string }[] = []; // Array of secondary prompts
  public isLoading = false;


  constructor(
    private fileService: FileService,
    private _stratsService: StratsService,
    private _widgetService: WidgetService,
    private _indicatorsService: IndicatorsService,
    private _socketService: SocketService,
    private _chatService: ChatService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
    if ((<any>window).strategyEditors) {
      (<any>window).strategyEditors.push(this);
    } else {
      (<any>window).strategyEditors = [this];
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  init() {
    this._stratsService.load(this.teamName).subscribe((response) => {
      this.strategies = <any[]>response;
      this.selectStrategy(null, this.strategies.find(x => x.name === this.name));
    });

    this._subs.add = this._indicatorsService.load(this.teamName).subscribe(async (response) => {
      const indicators = response?.filter((x) => x.type).sort();
      indicators.forEach((indicator) => {
        this.config.fields[indicator.name] = { name: indicator.description, type: 'number', value: indicator.name };
      });
    });

    this._socketService.joinRoom('llm');
    this._subs.add = this._socketService.subscribeToRoomEvents('llm', (data: any) => {
      if (data.origin === 'udf' && data.definition == this.name) {
        this.isLoading = false;
        this.messages = [{ isSelf: false, content: data.msg }];
        this.input = JSON.stringify(data?.input)
      }
    });

    this._chatService.modelsAsync().then(({ revisors, hasAllModels }) => {
      this.availableModels = revisors;
    }).catch(console.warn);

  }

  addAnalysis(): void {
    // Add a new analysis
    this.analyses.push({ name: '', prompt: '' });
    this.selectedAnalysisIndex = this.analyses.length + 1; // Switch to the newly added tab
  }




  removeAnalysis(index: number): void {
    this.analyses.splice(index, 1);
    this.selectedAnalysisIndex = Math.max(0, this.selectedAnalysisIndex - 1); // Adjust selected tab
  }

  selectPrompt(type: 'system' | 'final' | 'analysis', index?: number): void {
  this.selectedPrompt = { type, index };
}
onTabChange(index: number): void {
  // Check if "plus tab" was clicked
  if (index === this.analyses.length + 2) { // +2 to account for system and final tabs
    // Do not allow switching to the plus tab
    this.selectedAnalysisIndex = this.analyses.length + 1; // Switch to the last real tab
  }
}

onPlusButtonClick(event: MouseEvent): void {
  event.preventDefault(); // Prevent default button behavior
  event.stopPropagation(); // Stop event propagation
  this.addAnalysis(); // Add a new analysis
}

get currentPromptContent(): string {
  if (this.selectedPrompt.type === 'system') return this.systemPrompt;
  if (this.selectedPrompt.type === 'final') return this.finalPrompt;
  if (this.selectedPrompt.type === 'analysis' && this.selectedPrompt.index !== undefined) {
      return this.analyses[this.selectedPrompt.index]?.prompt || '';
  }
  return '';
}

set currentPromptContent(value: string) {
  if (this.selectedPrompt.type === 'system') this.systemPrompt = value;
  if (this.selectedPrompt.type === 'final') this.finalPrompt = value;
  if (this.selectedPrompt.type === 'analysis' && this.selectedPrompt.index !== undefined) {
      this.analyses[this.selectedPrompt.index].prompt = value;
  }
}

generateJson(): object {
  return {
      model: this.model,
      system_prompt: this.systemPrompt,
      analyses: this.analyses.filter(a => a.name && a.prompt),
      final_prompt: this.finalPrompt
  };
}


  selectStrategy(event, strategy) {
    this.selectedStrategy = strategy;
    this.query = null;
    this.code = null;

    if (strategy.path.endsWith('.json')) {
      this.fileService.get(this.teamName, strategy.path).then((code) => {
        this.query = JSON.parse(code);
        this.selectedStrategy.lastSavedValue = code;
      });
    } else if (strategy.path.endsWith('.py')) {
      this.fileService.get(this.teamName, strategy.path).then((code) => {
        this.code = code;
        this.selectedStrategy.lastSavedValue = code;
      });
    }
    else if (strategy.path.endsWith('.llm')) {
      this.codeEditorOptions.language = "text";
      this.fileService.get(this.teamName, strategy.path).then((data) => {
        strategy = JSON.parse(data);

        // Set prompts from the loaded strategy
        this.systemPrompt = strategy.system_prompt || "You are a financial assistant analyzing stock data.";
        this.finalPrompt = strategy.final_prompt || "Analyze the following stock data...\n\n{data_json}";
        this.analyses = strategy.analyses || [];

        // Set the selected model
        this.model = strategy.model || 'llama3.2:latest';

        // Track the last saved value
        this.selectedStrategy.lastSavedValue = JSON.stringify(strategy, null, 2);
      }).catch(error => {
        console.error("Failed to load .llm strategy:", error);
        this.systemPrompt = "You are a financial assistant analyzing stock data.";
        this.finalPrompt = "Analyze the following stock data...\n\n{data_json}";
        this.analyses = [];
      });
    }

  }

  public invoke($event) {
    this.isLoading = true;
    this.save();
    this.codeSubject.next($event);
    this._widgetService.stratEdit$.next({ parentId: this.parentId });
  }

  public codeChanged(event) {
    this.codeSubject.next(event);
    this._widgetService.stratEdit$.next({ parentId: this.parentId });
  }

  codeKeypress($event) {
    if (($event.ctrlKey || $event.metaKey) && $event.key == 's') {
      $event.stopPropagation();
      $event.preventDefault();
      this.save();
    }
  }

  public save() {
    if(this.selectedStrategy.path.endsWith('.py')) {
      this.fileService.postAsync(this.teamName, this.selectedStrategy.path, "py", this.code);
    }
    else if(this.selectedStrategy.path.endsWith('.llm')) {
      const content = this.generateJson();
      var sub = this.fileService.post(this.teamName, this.selectedStrategy.path, "json", content).subscribe(x => {
        sub.unsubscribe();
      })
    }
    else {
      console.error("Unrecognized file type")
    }
  }

  public $changeModel(model: string): void {
    this.model = model;
    this.modelName = this.availableModels.find((availableModel) => availableModel.model === model)?.name ?? 'Llama3.1';
  }

  close() {
    if (this.selectedStrategy && this.selectedStrategy.type == 'python') {
      this.selectedStrategy = null;
    } else {
      super.close();
    }
  }
}
